<template>
  <div class="height-100" style="background-color: #F8F8F8;">
    <!-- <div class="t-a-c bgc-white head" style="font-size: 1.1rem; font-weight: 600;color: black;">分类</div> -->
    <div class="fist">
      <div class="left-nav" ref="leftscoll">
        <van-sidebar v-model="activeKey" @change="onChange">
          <van-sidebar-item v-for="(item, index) in category" :key="index" :title="item.name" />
        </van-sidebar>
      </div>
      <div class="class-tabs" v-if="dataList">
        <div style=" border-radius: 0.6rem; padding: 1rem 0;">
          <div v-for="(item1, index1) in tempList" :key="index1" class="goodline">
            <div style="display: inline; " v-for="(item2, index2) in item1" :key="index2" class="cardsty" @click="goodOne(index1, index2,item2.jumpPageFlag)">
              <div class="boxOne">
                <van-image lazy-load fit="contain" class="goodImg" :src="item2.icon"  />
              </div>
              <div class="boxTwo">
                <div class="goodtitle">{{ item2.name }}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import AllNav from "../../components/footnav/AllNav.vue";
export default {
  name: "MovieClassification",
  props: {},
  data () {
    return {
      // 一级标题
      activeKey: 0,
      // 二级标题
      active: null,
      category: [

      ],
      level2: [

      ],
      tempList: [],
      //页面商品数据
      dataList: [],
      indexName: null,
      entorcode: '',
      cityCode: '',
      upgradeFlag: '',
      bathId: '',
    };
  },
  components: { AllNav },
  methods: {
    // 分类一级名字
    getData () {
      this.$api
        .classifyNew(this.bathId, this.upgradeFlag,this.cityCode)
        .then((res) => {
          if (res.code === 0) {
            this.category = res.data
            let selectIndex = 0
            if (this.indexName) {
              this.category.forEach((element, index) => {
                if (this.indexName == element.name) {
                  selectIndex = index
                }
              });
            }
            // this.activeKey = selectIndex
            // this.active = this.category[selectIndex].name
            // this.onClick(this.category[selectIndex].id)
            this.delayMethod(selectIndex)
          }
        })
        .catch((err) => {
          console.log(err);
        });

    },
    delayMethod (index) {
      setTimeout(() => {
        this.onChange(index)
      }, 200);
    },

    // 分类页面数据
    onClick (val) {
      this.tempList.length = 0
      this.$api
        .secondClassifyNew(val, this.bathId, this.upgradeFlag,this.cityCode)
        .then((res) => {
          if (res.code === 0) {
            this.dataList = res.data;
            this.tempList = this.listTemp();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 一级导航切换事件 默认商品显示页面
    onChange (index) {
      this.activeKey = index;
      this.active = this.category[index].name;

      // let a = this.level2[this.activeKey][0].mallSubId;3
      //侧边栏选中 设置滚动位置
      // if(index>2){
      //   this.$refs.leftscoll.scrollTop= (index-3)*this.$refs.leftscoll.children[0].children[0].clientHeight
      this.scollAnima(index)
      // }
      this.onClick(this.category[index].id);
    },

    scollAnima (index) {
      let _this = this
      let num = 1
      const oldTop = this.$refs.leftscoll.scrollTop;
      let speed = ((index - 3) * this.$refs.leftscoll.children[0].children[0].clientHeight - oldTop) / 5;
      if (index > 2) {
        let timer = setInterval(() => {
          _this.$refs.leftscoll.scrollTop = oldTop + speed * num
          num = num + 1;

          if (num == 6) {
            clearInterval(timer)
          }

        }, 30)
      }


    },
    //  查看详情
    goodOne (index1, index2,flag) {
      if (flag) {
        this.$api
          .getEntrance(flag)
          .then((res) => {
            if(res.code === 0){
           window.location.href = res.data;
            }else{
              this.$Toast.fail(res.msg)
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let index = 3 * (index1) + index2
        // //  传递参数
        this.$router.push({
          path: "/GoodsList",
          query: {
            index1: this.activeKey,
            index2: (index + 1),
          },
        });
      }

    },
    listTemp () {
      let index = 0
      const count = 3
      const arrTemp = []
      const experts = this.dataList
      for (let i = 0; i < this.dataList.length; i++) {
        index = parseInt(i / count)
        if (arrTemp.length <= index) {
          arrTemp.push([])
        }
        arrTemp[index].push(experts[i])
      }
      return arrTemp
    },
    initView() {
      this.entorcode = localStorage.getItem('entrCode')
      this.activeKey = this.$route.query.activeKey ? parseInt(this.$route.query.activeKey) : 0
      this.indexName = this.$route.query.indexName ? this.$route.query.indexName : null
      this.upgradeFlag = localStorage.getItem("upgradeFlag");
      this.bathId = localStorage.getItem("bathId");
      this.cityCode = localStorage.getItem("cityCode");
      this.getData();
      this.listTemp();
    }
  },
  mounted () {
  },
  beforeRouteEnter(to, from, next) {
    to.meta.keepAlive = true
    if (from.path !== "/GoodsList") {
      next((vm) => {
        vm.$nextTick(() => {
          vm.initView()
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0
        })
      });
    } else {
      next();
    }
  },



  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.fist {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 100vh;
}

.cardsty {
  width: 30%;
  text-align: center;
  background: white;
  padding: 1%;
  overflow: hidden;
  margin: 1px;
  display: flex;
}

.boxOne {
  height: 80%;
  width: 100%;
}

.boxTwo {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // margin-top: 0.125rem;
}

.goodImg {
  height: 75%;
  width: 75%;
  margin-top: 0.5rem;
  // height: 23.6vw;
  // width: 20.333vw;
}

.headtitle {
  width: 100%;
  background-color: #f8f8f8;
  color: black;
  padding-top: 1rem;
  font-size: 1rem;
  padding-bottom: 1rem;
}

.goodtitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  text-align: center;
  // margin-left: 14px;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goodline {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.class-tabs {
  flex: 1;
  margin-left: 1rem;
  margin-right: 0.5rem;
  overflow: scroll;


}

.left-nav {
  overflow: scroll;
}

.head {
  position: fixed;
  width: 100%;
  height: 2rem;
  z-index: 999;
  overflow: hidden;
  padding: 0.5rem;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: none;
}

.van-sidebar {
  width: 6.5rem;

  .van-sidebar-item {
    background-color: white;
    color: #403d3d;
    font-size: 0.875rem;
    width: 6.5rem;
    line-height: 1.2rem;
    text-align: center;
  }

  .van-sidebar-item--select::before {
    position: absolute;
    background-color: #d33b2e;
    height: 2.5rem;
    right: inherit;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }

  .van-sidebar-item--select,
  .van-sidebar-item--select:active {
    background-color: #f8f8f8;

    color: #d33b2e;
  }
}
</style>